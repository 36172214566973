import axios from "axios";
import router from "@/router/index";
import store from "@/stores/index";

export default {
  namespaced: true,

  state() {
    return {
      adsCount: 0,
      popularParts: [],
      loadingPopularParts: false,
      categoryParts: [],
      loadingCategoryParts: false,
      sort: null,
      sortMenuItems: [],
      pageOffset: 1,
    };
  },
  mutations: {
    setAdsCount(state, count) {
      state.adsCount = parseInt(count);
    },
    loadPopularParts(state, parts) {
      state.popularParts = parts;
    },
    loadPartsByFilter(state, parts) {
      state.categoryParts = parts;
    },
    setSortMenuItems(state, items) {
      state.sortMenuItems = items;
    },
    selectSortMenuItem(state, sortOrder) {
      state.sort = sortOrder;
    },
  },
  actions: {
    async loadPopularParts({ state, commit }) {
      if (state.popularParts.length > 0) return;

      state.loadingPopularParts = true;

      let params = {
        j: "getPopularAds",
      };

      return axios
        .get(window.APP_CONFIG.API_URL, { params })
        .then((response) => {
          if (!Array.isArray(response.data)) return;
          commit("loadPopularParts", response.data);
        })
        .finally(() => {
          state.loadingPopularParts = false;
        });
    },
    async loadPartsByFilter({ state, commit }, query = {}) {
      // Reset
      state.categoryParts = [];
      state.loadingCategoryParts = true;
      state.pageOffset = query.p || 1;

      commit("setAdsCount", 0);
      let params = {
        j: "getAdsByFilter",
        lang_id: store.state.global.locale,
        main_category_name: query.main_category_name || null,
        category_name: query.category_name || null,
        category_parent: query.category || null,
        manufacturer_id: query.manufacturer_id || null,
        model_id: query.model_id || null,
        keywords: query.keywords || null,
        sort: query.sort || null,
        vehicle_type: query.vehicle || null, // TODO: Remove, Used in Search Header
        p: query.p || null,
      };

      return axios
        .get(window.APP_CONFIG.API_URL, { params })
        .then((res) => {
          if (!Array.isArray(res.data)) {
            console.error("ERROR response data is not a array!");
            return;
          }
          commit("loadPartsByFilter", res.data);

          if (res.headers["ads-count"]) {
            commit("setAdsCount", res.headers["ads-count"]);
          }
        })
        .finally(() => {
          state.loadingCategoryParts = false;
        });
    },
    async postAdWithAccount(_, data = {}) {
      let params = {
        j: "postAdWithAccount",
      };

      const formData = new FormData();

      for (const key in data) {
        const value = data[key];

        if (Array.isArray(value)) {
          const arrayKey = `${key}[]`;
          value.forEach((v) => {
            formData.append(arrayKey, v);
          });
        } else {
          formData.append(key, value);
        }
      }

      return axios.post(window.APP_CONFIG.API_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params,
      });
    },

    async sort({ state, commit, dispatch }, value) {
      await commit("selectSortMenuItem", value);

      let query = {
        ...router.currentRoute._value.query,
        sort: state.sort,
      };

      if (value === null) {
        delete query.sort;
      }

      dispatch("loadPartsByFilter", query);
      await router.push({ path: router.currentRoute._value.path, query });
    },
  },
};
