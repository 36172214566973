import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueScreen from "vue-screen";
import axios from "axios";
import { i18n, configureLocale } from "@/plugins/i18n";
import VueAxios from "vue-axios";
import store from "@/stores/index";
import dayjs from "dayjs";
import PrimeVue from "primevue/config";
import { createHead } from "@unhead/vue";
import { initializeAnalytics } from '@/services/analytics';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import { setupErrorHandling } from "@/services/error-service";

// Lazy load PrimeVue components
const Toast = () => import("primevue/toast");
const paramsLocale = import.meta.env.VUE_APP_I18N_LOCALE || "sv";

// load locale messages
configureLocale(i18n, paramsLocale);

// Import styles
import "@fontsource/roboto";
import "primevue/resources/themes/saga-orange/theme.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@/assets/global.css";

// Create utility functions
const utilityMethods = {
  numFormat: (currency, value) => {
    if (!currency || !value) return;
    return new Intl.NumberFormat("en", {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
    }).format(value);
  },
  isEmpty: (value) => {
    if (value == null || value === 0) return true;
    return typeof value === "object" ? !Object.keys(value).length : false;
  },
  replaceNewLine: (text) => text?.replace(/(?:\r\n|\r|\n)/g, "<br>") ?? text,
};

// Initialize app
const app = createApp(App);

// Configure axios
const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Auth"] = `Bearer ${token}`;
}

// Setup app
app.use(i18n)
   .use(VueScreen, "bootstrap")
   .use(VueAxios, axios)
   .use(PrimeVue, { ripple: true })
   .use(ToastService)
   .use(ConfirmationService)
   .use(router)
   .use(store)
   .use(createHead());

// Register global properties
app.config.globalProperties.$dayjs = dayjs;
app.mixin({ methods: utilityMethods });

// Register components
const components = { Button, InputText, Card, Toast };
Object.entries(components).forEach(([name, component]) => {
  app.component(name, component);
});

axios.interceptors.request.use((request) => {
  // console.log('Starting Request', JSON.stringify(request, null, 2))
  return request;
});

axios.interceptors.response.use((response) => {
  // console.log('Response:', JSON.stringify(response, null, 2))
  return response;
});

// Mount app after data fetch
(async () => {
  await Promise.all([
    store.dispatch("global/getAppSettings"),
    store.dispatch("catalog/loadInitializationData"),
  ]);

  app.mount("#app");

  // Error handling
  setupErrorHandling(app)

  // Analytics setup
  initializeAnalytics();
})();