import { App } from 'vue';
import { reportError } from './api';

const errorCache = new Map();
const CACHE_TIMEOUT = 60000; // 1 minute

function isSimilarError(error1: string, error2: string): boolean {
  return error1.split(':')[0] === error2.split(':')[0];
}

function shouldReportError(error: unknown): boolean {
  return window.APP_CONFIG.ENVIRONMENT === 'prod';
}

function handleErrorCaching(errorKey: string): boolean {
  const now = Date.now();
  const cachedError = errorCache.get(errorKey);
  if (cachedError && now - cachedError.timestamp < CACHE_TIMEOUT) {
    cachedError.count++;
    return false;
  }
  for (const [key, value] of errorCache.entries()) {
    if (isSimilarError(key, errorKey) && now - value.timestamp < CACHE_TIMEOUT) {
      value.count++; return false;
    }
  }
  errorCache.set(errorKey, { timestamp: now, count: 1 });
  return true;
}

export function setupErrorHandling(app: App) {
  app.config.errorHandler = (error, instance, info) => {
    // Report error to backend

    if (shouldReportError(error)) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      if (handleErrorCaching(errorMessage)) {
        reportError(error, info);
      }
    }
    console.error("Global error:", error);
    console.error("Vue instance:", instance);
    console.error("Error info:", info);
  };
}