import axios from 'axios';
import { Store } from 'vuex'
import globalStore from '@/stores/global'

interface RootState {
  global: {
    locale: string
    // other state properties
  }
}

const typedStore = globalStore as unknown as Store<RootState>


export const login = (email: string, pass: string) => {
  const formData = new FormData();
  formData.append("email", email);
  formData.append("pass", pass);
  return axios.post(window.APP_CONFIG.API_URL, formData, { params: { j: "login" } });
};

export const signupResendValidation = (email: string) => {
  const locale = typedStore.getters['global/locale']
  const formData = new FormData();
  formData.append("email", email);
  return axios.post(window.APP_CONFIG.API_URL, formData, { params: { j: "signupResendValidation", lang_id: locale } });
};

export const signup = (firstName: string, lastName: string, zipcode: string, phone: string, email: string, password: string) => {
  const formData = new FormData();
  const locale = typedStore.getters['global/locale']
  formData.append("firstName", firstName);
  formData.append("lastName", lastName);
  formData.append("zipcode", zipcode);
  formData.append("phone", phone);
  formData.append("email", email);
  formData.append("password", password);
  return axios.post(window.APP_CONFIG.API_URL, formData, { params: { j: "signup", lang_id: locale } });
};

export const getUserProfile = () => {
  const params = {
    j: "getProfile",
  };

  return axios.get(window.APP_CONFIG.API_URL, { params });
};

// Error reporting
let lastErrorTime = 0;
const MIN_ERROR_INTERVAL = 5000; // 5 seconds between errors

export const reportError = (error: any, info?: string) => {
  const now = Date.now();
  if (now - lastErrorTime < MIN_ERROR_INTERVAL) {
    return; // Skip if too soon after last error
  }
  lastErrorTime = now;

  const params = {
    j: "reportError",
  };
  const body = {
    error: error?.message || error,
    stack: error?.stack,
    info,
    timestamp: new Date().toISOString(),
  };
  axios.post(window.APP_CONFIG.API_URL, body, { params});
};
